
import { Component, Mixins, Vue } from "vue-property-decorator";
import { namespace, Getter } from "vuex-class";
import { get, isEqual } from "lodash";

import { ServerDetails, ServerType } from "@/store/devices/types";
import { UserPermissions, FeaturesList, CancellableQuery, PaginatedSearchQueryParams } from "@/store/types";
import NavHeader from "@/components/NavHeader.vue";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import DeviceList from "@/components/devices/DeviceList.vue";
import ApplianceList from "@/components/devices/ApplianceList.vue";
import ExtraValueField from '@/components/devices/ExtraValueField.vue'
import PortField from "@/components/devices/PortField.vue";
import OpsLinkCameraImport from "@/components/OpsLinkCameraImport.vue";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import api from "@/services/api.service";
import { ModalItem } from "@/components/table/generic-update-modal.vue";
import { Constants } from "@/types/sv-data/devices/Constants";
import PaginatedSearch from "@/mixins/PaginatedSearch";

const GenericTableStore = namespace("GenericTable");
const Areas = namespace("areas");
const Subscription = namespace("subscription");

export interface ServerRow extends ServerDetails {
	serverType: ServerType;
	groupName: string;
	extraValueName: string;
	deviceTypeTitle: string;
}

@Component({
	components: {
		"nav-header": NavHeader,
		"generic-table": GenericTable,
		"ops-link-camera-import": OpsLinkCameraImport,
	}
})
export default class DevicesPage extends Mixins(PaginatedSearch) {
	$refs!: {
		genericTable: any;
	};

	@Getter getPermissions: UserPermissions;
	@Getter("getFeaturesList") private featuresList: FeaturesList;
	@Getter("getUserId") private currentUserId!: number;
	@Getter("getUserTenantGroupId") private usersTenantGroupId: number;
	@GenericTableStore.Getter private getModalRow: any
	@Areas.Getter private getAreaTitle: (id) => string;
	@Areas.Action fetchAreaDictionary: () => Promise<void>;
	@Areas.State areaDictionary: Map<number, string>;

	@Subscription.Action reloadSubscription: () => Promise<void>;


	private serverList: ServerRow[] = [];
	private serverTypes: ServerType[] = [];
	private isLoading: boolean = false;

	private columns: TableHeader[] = [
		{
			title: "Device ID",
			key: "serverID",
			order: 1,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The Device ID",
			searchable: false,
			visible: false,
			dataType: "input",
			sortable: true,
			sortKey: "serverId",
		},
		{
			title: "Device Name",
			key: "title",
			order: 2,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The device title",
			searchable: true,
			visible: true,
			dataType: "input",
			isTermLabel: true,
			sortable: true,
			isSortedByDefault: true,
		},
		{
			title: "Area Name",
			key: "groupName",
			order: 3,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The name of the area that the device belongs to",
			searchable: true,
			visible: true,
			dataType: "input",
			sortable: true,
			sortKey: "Area",
		},
		{
			title: "Device Type",
			key: "deviceTypeTitle",
			order: 4,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The device type",
			searchable: true,
			visible: true,
			dataType: "input",
			sortable: true,
			sortKey: "Type",
		},
		{
			title: "Host",
			key: "host",
			order: 5,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The device host",
			searchable: true,
			visible: true,
			dataType: "input",
			sortable: true,
		},
		{
			title: "Port",
			key: "port",
			order: 6,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The device port",
			searchable: true,
			visible: true,
			dataType: "number",
			sortable: true,
		},
		{
			title: "Username",
			key: "username",
			order: 7,
			sortOrder: 0,
			sortOrderReversed: false,
			description: "The device username",
			searchable: true,
			visible: true,
			dataType: "input",
			sortable: true,
		},
	];

	private get ModalItems() {
		return [
			{
				title: "Device Name",
				key: "title",
				dataType: "text",
				readOnly: false,
				placeholder: "Device",
				order: 1,
				maxLength: 250,
				required: true,
			},
			{
				title: "Area",
				key: "groupID",
				dataType: "component",
				readOnly: false,
				data: AreaTreeSelect,
				props: {
					clearable: false,
					reduce: a => a.id
				},
				defaultValue: this.usersTenantGroupId,
				required: true
			},
			{
				title: "Device Type",
				key: "serverTypeID",
				dataType: "vselect3",
				props: {
					reduce: (t) => t.serverTypeID
				},
				data: this.filteredDeviceTypes,
				readOnly: false,
				required: true
			},
			{
				title: "Ops Link",
				key: "applianceId",
				dataType: "component",
				readOnly: false,
				visible: this.isApplianceEnabled,
				data: ApplianceList,
				required: this.isApplianceOnly,
				description: "<a href='https://help.sureviewops.com/hc/en-us/articles/360013622797-Ops-Link' target='_blank'>More Information</a>",
				props: {}
			},
			{
				title: "IP / Hostname",
				key: "host",
				dataType: "text",
				readOnly: this.isApplianceOnly && this.isApplianceSelected,
				placeholder: "127.0.0.1",
				order: 3,
				maxLength: 100,
				required: false
			},
			{
				title: "",
				key: "port",
				dataType: "component",
				props: {
					readonly: this.isApplianceOnly && this.isApplianceSelected,
					placeholder: "0",
					title: 'Port'
				},
				order: 4,
				data: PortField,
				required: false
			},
			{
				title: "Username",
				key: "username",
				dataType: "text",
				readOnly: this.isApplianceOnly && this.isApplianceSelected,
				placeholder: "Username",
				order: 5,
				maxLength: 50,
				required: false
			},
			{
				title: "Password",
				key: "password",
				dataType: "password",
				readOnly: this.isApplianceOnly && this.isApplianceSelected,
				placeholder: "Password",
				order: 6,
				maxLength: 50,
				required: false,
				newOnly: false
			},
			{
				title: '',
				key: "extraValue",
				readOnly: false,
				placeholder: "",
				props: {
					title: this.extraValueName
				},
				order: 6,
				data: ExtraValueField,
				dataType: "component"
			},
			{
				title: "Raise Alarms Serially",
				key: "isRaiseIndividual",
				dataType: "checkbox",
				readOnly: false,
				order: 7,
				required: false,
				description: "Raise every alarm that matches this device as its own event",
				visible: get(this.featuresList, ["Alarms", "AdvancedAlarmGrouping"]) ? true : false,
			},
			{
				title: "Raise Alarms Grouped",
				key: "isRaiseGrouped",
				dataType: "checkbox",
				readOnly: false,
				order: 7,
				required: false,
				description: "Raise every alarm that matches this device together as a single, grouped event",
				visible: get(this.featuresList, ["Alarms", "AdvancedAlarmGrouping"]) ? true : false,
			},
			{
				title: "Cameras",
				key: "devices",
				dataType: "component",
				readOnly: false,
				visible: this.allowsCameras,
				data: DeviceList,
				componentHasValidation: true,
				props: {
					usesExtraCameraIds: this.usesExtraCameraId,
					usesDeviceExtraValue: this.usesDeviceExtraValue,
					parentTitle: this.currentTitle,
					deviceTypes: this.filteredDeviceTypes
				},
				required: false
			}
		] as ModalItem[];
	}

	private isAddingWithOpsLink: boolean = false;
	private isCameraDetectionAvailable: boolean = false;

	private totalRecords: number = 0;

	private get isShowCameraDevicesOnly() {
		return this.isDevicesEnabled && this.isDevicesEnabled.ShowCameraDevicesOnly;
	}

	private get sortedDeviceTypes() {
		return this.serverTypes.sort(({title: a}, {title: b}) => {
			if (a < b) return -1;

			return b < a ? 1 : 0
		});
	}

	private get filteredDeviceTypes() {
		const deviceTypes = this.sortedDeviceTypes.filter(({ hidden }) => !hidden)

		return this.isShowCameraDevicesOnly
			? deviceTypes.filter(({ numCameras }) => numCameras !== 0)
			: deviceTypes
	}

	private get isExtraValueName() {
		return  this.getModalRow !== null && this.getModalRow.extraValueName;
	}

	private get modalRowTitle () {
		return this.getModalRow && this.getModalRow.title;
	}

	private get allowsCameras() {
		return this.selectedServerType !== undefined && this.selectedServerType.numCameras != 0;
	}

	private get currentTitle() {
		return this.modalRowTitle || "Camera";
	}

	private get extraValueName() {
		return this.isExtraValueName || "Extra Value";
	}

	private get selectedServerType(): ServerType {
		if (!this.getModalRow || this.serverTypes.length === 0) {
			return undefined;
		}

		return this.serverTypes.find(type => {
			return type.serverTypeID === this.getModalRow.serverTypeID
		});
	}

	private get usesDeviceExtraValue() {
		return this.selectedServerType !== undefined && this.selectedServerType.usesDeviceExtraValue;
	}

	private get usesExtraCameraId() {
		return this.selectedServerType !== undefined && this.selectedServerType.usesExtraCameraIDs.toString().toLowerCase() == "true";
	}

	private filterCameraOnlyServers(servers: ServerDetails[]) {
		return servers.filter(({ serverTypeID }) => {
			const currentType = this.serverTypes.find(type => type.serverTypeID == serverTypeID);
			return currentType && currentType.numCameras != 0;
		});
	}

	private getServerTypeId(serverTypeID) {
		return this.serverTypes.find(({serverTypeID: id}) => id == serverTypeID);
	}

	private async updateData(paginatedSearchQueryParams?: PaginatedSearchQueryParams) {
		try {
			this.isLoading = true;

			const cancellableQuery = this.generateNewPaginatedSearchRequest(paginatedSearchQueryParams);

			const [serversResult, serverTypes] = await Promise.all([
				api.getServers(cancellableQuery),
				api.getServerTypes()
			]);

			const servers = serversResult.data;

			this.totalRecords = serversResult.totalRecords;

			this.serverTypes = serverTypes;

			if (!servers || servers.length == 0) {
				this.serverList = [];
				return;
			}

			const serverList = this.isCameraOnly ?
				this.filterCameraOnlyServers(servers) :
				servers;

			this.serverList = this.formattedServerList(serverList);
		} catch (ex) {
			console.log("Unexpected error updating state: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private formattedServerList(serverList: ServerDetails[]): ServerRow[] {
		return serverList.map(server => {
			const isReadOnly = !!server.syncSystemId && !this.getPermissions.canOverrideGroupSync && !this.getPermissions.isSystemAdmin;
			const serverType = this.getServerTypeId(server.serverTypeID);
			const groupName = this.getAreaTitle(server.groupID);
			return {
				...server,
				serverType,
				groupName,
				extraValueName: get(serverType,'extraValueName') || '',
				deviceTypeTitle: get(serverType,'title') ||  '',
				readOnly: isReadOnly,
				readOnlyMessage: isReadOnly ? "You do not have permission to edit Sync Devices" : null
			};
		});
	}

	private async onDelete(serverToDelete) {
		await api.deleteServer(serverToDelete.serverID);
		await this.updateData(this.mostRecentSearchParams);
	}

	private async onAddNew(serverToAdd: ServerRow) {
		let newServer: ServerDetails = {
			serverID: undefined,
			serverTypeID: serverToAdd.serverTypeID,
			title: serverToAdd.title,
			groupID: serverToAdd.groupID,
			host: serverToAdd.host,
			port: serverToAdd.port || 0,
			username: serverToAdd.username,
			password: serverToAdd.password,
			extraValue: serverToAdd.extraValue,
			applianceId: serverToAdd.applianceId
		};

		newServer.serverID = await api.addServer(newServer);
		newServer.devices = serverToAdd.devices;
		await this.onAddDevice(newServer);
		await this.updateData(this.mostRecentSearchParams);
	}

	private async onSave(serverToUpdate: ServerRow) {
		const updatedDevices = [...serverToUpdate.devices];
		let updatedServer: ServerDetails = {
			serverID: serverToUpdate.serverID,
			serverTypeID: serverToUpdate.serverTypeID,
			title: serverToUpdate.title,
			groupID: serverToUpdate.groupID,
			host: serverToUpdate.host,
			port: serverToUpdate.port || 0,
			username: serverToUpdate.username,
			password: serverToUpdate.password,
			extraValue: serverToUpdate.extraValue,
			applianceId: serverToUpdate.applianceId
		}

		await api.updateServer(updatedServer);

		updatedServer.devices = updatedDevices;
		await this.onAddDevice(updatedServer);
		await this.updateData(this.mostRecentSearchParams);

		// GenericTable isn't  designed to work without loading from scratch.
		// This call is required to force update its contents after saving, as Device Setup does not close the update modal after saving.
		await this.$refs.genericTable.openModel({...updatedServer, devices: updatedDevices}, false, false);
	}

	private async onAddDevice(serverToAdd: ServerDetails) {
		if (!serverToAdd.devices) return;

		const oldDevices = await api.getDevices(serverToAdd.serverID);

		await this.updateOldDevices(serverToAdd, oldDevices);
		await this.addNewDevices(serverToAdd, oldDevices);
	}

	private updateOldDevices(serverToAdd, oldDevices) {
		return Promise.all(oldDevices.map(async oldDevice => {
			const updatedDevice = serverToAdd.devices.find(d => d.deviceID === oldDevice.deviceID);
			if(!updatedDevice) return api.deleteDevice(oldDevice.deviceID);

			const isDeviceModified = !isEqual(updatedDevice, oldDevice);
			if(!isDeviceModified) return;

			updatedDevice.groupID = serverToAdd.groupID;
			await api.updateDevice(updatedDevice);
		}))
	}

	private addNewDevices(serverToAdd, oldDevices) {
		return Promise.all(serverToAdd.devices.map(async newDevice => {
			newDevice.groupID = serverToAdd.groupID;

			const device = oldDevices.find(({ deviceID }) => newDevice.deviceID == deviceID);
			// The device exists in the new list, but not the old
			if(device) return

			try {
				await api.addDevice(this.setDataNewDevice(newDevice, serverToAdd.serverID));
			} catch (ex) {
				if(ex.response && ex.response.status === 566){
					Vue.prototype.$notify({
						type: "error",
						title: "Error",
						text: ex.response.data
					});
				} else {
					throw ex;
				}
			}
		}));
	}

	private setDataNewDevice(newDevice, serverID) {
		return {
			...newDevice,
			serverID,
			deviceTypeId: 1,
			SettingsMeta: Constants.DEFAULT_CAMERA_SETTINGS_META
		};
	}

	private async onModalOpen(modalItem) {
		if (get(modalItem, 'serverID')) {
			modalItem.devices = await api.getDevices(modalItem.serverID);
			modalItem.devices.sort(function(a, b) {
				if (a.input1 > b.input1) return 1;

				return a.input1 < b.input1 ? -1 : 0;
			});
			modalItem.serverType = this.getServerTypeId(modalItem.serverTypeID);
			modalItem.groupName = this.getAreaTitle(modalItem.groupID);
			modalItem.extraValueName = get(modalItem.serverType,'extraValueName') || '';
			modalItem.deviceTypeTitle = get(modalItem.serverType,'title') ||  '';
			Vue.set(this.serverList, this.serverList.indexOf(modalItem), modalItem);
			return modalItem;
		}
	}

	// If feature is on / off
	private get isDevicesEnabled() {
		return get(this.featuresList, ["Devices"]);
	}

	private get isCameraOnly() {
		return get(this.featuresList, ["Devices", "ShowCameraDevicesOnly"]);
	}

	private get isSuiteEnabled(): boolean {
		return get(this.featuresList, ["Suite"]);
	}

	private get isApplianceEnabled() {
		return get(this.featuresList, ["Devices", "Appliances"]);
	}

	private get isApplianceSelected() {
		return !this.modalRowApplianceId;
	}

	private get modalRowApplianceId() {
		return this.getModalRow && this.getModalRow.applianceId !== null && this.getModalRow.applianceId != 0;
	}

	private get isApplianceOnly() {
		return get(this.featuresList, ["Devices", "Appliances", "ApplianceOnly"]);
	}

	private async mounted() {
		if (!this.isDevicesEnabled) {
			this.$router.go(-1);
		}

		if (this.isSuiteEnabled)
		{
			await this.reloadSubscription()
		}

		if (this.areaDictionary.size === 0) {
			await this.fetchAreaDictionary();
		}

		await this.updateData();
		await this.checkCameraDetectionAvailability();
	}

	private async checkCameraDetectionAvailability() {
		let isFeatureEnabled = get(this.featuresList, ["Devices", "Appliances", "CameraDetection"]);
		if (!isFeatureEnabled) {
			return false;
		}

		let availableDetectors = await api.getApplianceList();
		this.isCameraDetectionAvailable = availableDetectors.length !== 0;
	}
}
