




















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { get } from 'lodash'

@Component
export default class PortField extends Vue {
	@Prop() public value: any;
	@Prop() public title: string;
	@Prop() public placeholder: string;
	@Prop() public readonly: boolean;
	@Prop() public isAddingNew: boolean;
	@Prop() public model: any

	public localValue: any = null;

	@Watch('value', { immediate: true })
	public setLocalValue(value) {
		if(this.localValue === value) return
		this.localValue = value
	}

	@Watch('defaultPort')
	public extraValueNameChanged(cur, prev) {
		if(cur === prev) return
		this.localValue = cur
	}

	@Watch('localValue')
	public valueChanged() {
		this.$emit('input', this.currentLocalValue)
	}

	public get currentLocalValue() {
		return this.isEmptyLocalValue ? null : this.localValue
	}

	public get isEmptyLocalValue() {
		return this.localValue === '' || this.localValue === null || this.localValue === undefined
	}

	public get serverTypeID() {
		return get(this.model, 'serverTypeID')
	}

	public get defaultPort() {
		return get(this.serverTypeID, 'defaultPort')
	}
}
