






















import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class ExtraValueField extends Vue {
		@Prop() public value: any;
	    @Prop() public title: string;
		@Prop() public model: any

		public localValue: any = null;

		@Watch('localValue')
		public localValueChanged() {
			this.$emit('input', this.localValue)
		}

		@Watch('value', { immediate: true })
		public valueChanged() {
			if(this.value === null) return
			this.localValue = this.value
		}

		@Watch('isShowField', { immediate: true })
		public isShowFieldChanged(cur, prev) {
			 this.$emit('input', this.isShowField ? this.localValue : null)
		}

		public get serverTypeID() {
			return this.model && this.model.serverTypeID
		}

		public get isShowField() {
			return this.extraValueName !== null
		}

		public get extraValueName() {
			return this.serverTypeID && this.serverTypeID.extraValueName
		}
}
