
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { SubscriptionDto } from "@/store/subscription/types.ts";
import { FeaturesList } from "@/store/types";
import { get, debounce } from "lodash";
import { ServerType, CameraPreviewDeviceDetails } from "@/store/devices/types";
import CameraPreview from '../device-setup/CameraPreview.vue';

const Subscription = namespace("subscription");
const GenericTable = namespace("GenericTable");
const Session = namespace("sessions");
const SiteMonitor = namespace("siteMonitor");

interface DeviceRow {
	deviceID?: number
	title: string,
	input1: number,
	input2: number,
	deviceTypeID: number,
	transmitsAudio?: boolean,
	receivesAudio?: boolean,
	extraValue?: string
}

@Component({
	components: {
		"camera-preview": CameraPreview,
		VuePerfectScrollbar: VuePerfectScrollbar
	}
})
export default class DeviceList extends Vue {

	@Prop({ type: Boolean, default: false }) readonly: boolean;

	@Prop({ type: Boolean, default: true }) usesExtraCameraIds: boolean;

	@Prop({ type: Boolean, default: false }) usesDeviceExtraValue: boolean;

	@Prop({ type: String, default: "Camera" }) parentTitle: String;

	@Prop()
	public value: any;

	@Prop( { type: Boolean, default: false }) isAddingNew: boolean;

	@Prop() public model: any;

	@Prop({ default: [] }) public deviceTypes: ServerType[];

	@Getter("getFeaturesList") featuresList: FeaturesList;

    @GenericTable.State rowHasChanges: boolean;

	@Subscription.State subscription: SubscriptionDto;
	@Subscription.Action fetchSubscription: any;

	@SiteMonitor.Action private pollApplianceServerAndDeviceServerDetails: () => Promise<void>;
	@SiteMonitor.Action private stopPollingApplianceServerAndDeviceServerDetails: () => void;
	@SiteMonitor.Getter("getIsCameraEnabled") isCameraEnabled: (deviceId: number, test: string) => boolean;
	@SiteMonitor.Getter("getApplianceOfflineNotification") private applianceOfflineNotification: (deviceId: number) => string;

	public deviceList: DeviceRow [] = [];
	private devicePreviewDetails: CameraPreviewDeviceDetails;

	private cameraUsage: number = 0;
	private cameraUsageDifference: number = 0;
	private isCameraPreviewShown: boolean = false;
	private errors = {};

	public async mounted() {
		if (this.isBillingEnabled) {
			await this.fetchSubscription();
		}

	    this.cameraUsage = this.subscription?.cameras;
		if (this.value) {
			this.updateFromValue();
		}

		if(this.isAddingNew) {
			Array.from({ length: this.numCameras}, () => this.addDevice())
		}

		await this.pollApplianceServerAndDeviceServerDetails();
	}

	public beforeDestroy() {
		this.stopPollingApplianceServerAndDeviceServerDetails();
	}

	@Watch("value")
	valueChanged(value: any) {
		if (value) {
			this.updateFromValue();
		}
	}

	@Watch("deviceList", { deep: true })
	public updateDeviceList(deviceList): void {
		if (deviceList) {
			this.$emit("input", deviceList);
		}
	}

	@Watch("parentTitle")
	public parentTitleHandler(parentTitle, oldParentTitle) {
		const hasPreventRenaming = !parentTitle || oldParentTitle === parentTitle
		if(hasPreventRenaming) return
		this.renameCameraNameByParentTitle(parentTitle)
	}

	@Watch("numCameras")
	public addDefaultCameras() {
		if(!this.isAddingNew) return

		const { length } = this.deviceList
		this.deviceList.splice(0, length)

		Array.from({ length: this.numCameras}, () => this.addDevice())
	}

	public get renameCameraNameByParentTitle() {
		return debounce(this.renameCameraNameByTitle, 500)
	}

	public renameCameraNameByTitle(parentTitle): void {
		this.$emit("input",this.deviceList.map(device => {
			const number = device.title.match(/\d+$/)

			return {
				...device,
				title: `${parentTitle} ${(number && number[0]) || ''}`
			}
		}))
	}

	public get serverTypeID() {
		return get(this.model, 'serverTypeID');
	}

	public get extraValueName() {
		return get(this.serverTypeID, 'extraValueName');
	}

	public get numCameras() {
		return Math.abs(get(this.serverTypeID, 'numCameras') || 0);
	}

	private async addDevice() {
		if (this.isBillingEnabled) {
			await this.fetchSubscription();
			this.cameraUsage = this.subscription.cameras;
		}

		let inputs = this.deviceList.map(device => device.input1);

		let currentNumber = Math.max(...inputs) + 1;
		if (inputs.length == 0) {
			currentNumber = 1;
		}

		this.deviceList.push({ title: this.parentTitle + " " + currentNumber, input1: currentNumber, input2: -1, deviceTypeID: 1 });

		this.cameraUsageDifference++;
	}

	private async removeDevice(deviceId: any) {
		if (this.isBillingEnabled) {
			await this.fetchSubscription();
			this.cameraUsage = this.subscription.cameras;
		}
		let index = this.deviceList.findIndex(d => d.deviceID === deviceId);
		this.deviceList.splice(index, 1);
		this.cameraUsageDifference--;
	}
	/**
	 * Update selected groups list based on model change
	 */
	private updateFromValue() {
		this.deviceList = this.value;
	}

	private get isBillingEnabled(): boolean {
		return !!get(this.featuresList, ["Billing"]) || !!get(this.featuresList, ["Suite"]);
	}

    private get isCameraPreviewEnabled() {
        return get(this.featuresList, ["Devices", "CameraPreview"]);
    }

	private showOrUpdateCameraPreview(device: DeviceRow) {
        this.devicePreviewDetails = {
            title: device.title,
			deviceId: device.deviceID
        };
        this.isCameraPreviewShown = true;
    }

    private get deviceSupportsAudio(): boolean {
		if (!get(this.featuresList, ["Devices", "CameraAudio"])) {
			return false;
		}

		if (this.serverTypeID) {
			return this.serverTypeID.numAudioInputs !== 0 && this.serverTypeID.numAudioOutputs;
		}
		return false;
	}

	@Watch('filteredDeviceList', { deep: true })
	validationFilteredDeviceList() {
		if(!this.filteredDeviceList.length) return this.$emit('isValid', { isValid: true, name: 'DeviceList' })

		this.errors = this.filteredDeviceList.reduce((acc, cur) => {
			const key = cur.input1

			if(acc[key] !== undefined) {
				acc[key] += 1

				return acc
			}

			acc[key] = 0
			return acc
		}, {})

		this.$emit('isValid',  {
			isValid: Object.values(this.errors).every(v => !v),
			name: 'DeviceList'
		})
	}

	private get filteredDeviceList() {
		return this.deviceList.filter(d => d.deviceTypeID === 1);
	}

	public validate(): boolean {
        return this.isValid;
    }

    private get isValid(): boolean {
        if(this.subscription && this.subscription.limitedSubscription) {
            if((this.subscription.cameras + this.cameraUsageDifference) > this.subscription.camerasLimit) {
                return false;
            }
        }
        return true;
    }

	private handleCameraPreviewHidden(): void {
		this.isCameraPreviewShown = false;
		this.devicePreviewDetails = null;
	}
}
